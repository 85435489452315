import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { LeftBar, Modals, TopBar, BottomBar } from '@trader/containers';
import { breakpoints } from '@trader/themes';
import { dmaTabs } from '@trader/constants';
import { useInstruments, useWindowDimensions } from '@trader/hooks';

import { Root, Main, Routing, Body } from './styled';
import { observer } from 'mobx-react-lite';
import { useMst } from '@trader/store';

export const TradingLayout: React.FC = observer(() => {
  const store = useMst();
  const { width } = useWindowDimensions();

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  const breakpointSm = breakpoints?.values?.sm as number;
  const shouldDisplay = width > breakpointSm;

  useInstruments([symbol], true);

  return (
    <Fragment>
      <Root>
        <TopBar />
        <Main $isSidebarOpen>
          <Body>
            {shouldDisplay && <LeftBar tabsList={dmaTabs} />}
            <Routing>
              <Outlet />
            </Routing>
          </Body>
        </Main>
        <BottomBar />
      </Root>
      <Modals />
    </Fragment>
  );
});
